import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { useTranslation } from "next-i18next";

const FrequentlyAskedQuestions = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full px-4 py-14">
      <div className="text-center pb-10">
        <div className="md:flex md:justify-center space-y-2 md:space-y-0 text-3xl md:text-4xl">
          <h2 className="text-indigo-600 pr-2">{t("faq-title")}</h2>
          <h2 className="font-extralight">{t("faq-description")}</h2>
        </div>
      </div>
      <div className="mx-auto w-full max-w-md rounded-2xl bg-white p-2">
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-indigo-100 px-4 py-2 text-left text-sm font-medium text-indigo-900 hover:bg-indigo-200 focus:outline-none focus-visible:ring focus-visible:ring-indigo-500 focus-visible:ring-opacity-75">
                <span>{t("faq-maintenance-title")}</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-indigo-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                {t("faq-maintenance-description")}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
